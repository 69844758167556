import { atom } from 'recoil'

// 用户信息
export const userInfo = atom({
  key: 'user_info',
  default: {
    isLogin: false,
    isVip: false
  }
})

// 顶部热门搜索记录
export const hotSearchData = atom({
  key: 'hot_search_data',
  default: []
})

//全部页面数据缓存
export const allData = atom({
  key: 'all_data',
  default: []
})
//全部页面url地址
export const allUrl = atom({
  key: 'all_url',
  default: ''
})

// 体育模块公用缓存数据
export const sportUrlMap = atom({
  key: 'sport_url_map',
  default: []
})

// 播放器存储剧集内容
export const episodeInfo = atom({
  key: 'episode_info',
  default: []
})

// 下载页面存储tab类型
export const downloadType = atom({
  key: 'download_type',
  default: 'phone'
})

export const showSide = atom({ key: 'show_side', default: true })

// 移动端引导弹窗控制展示

export const h5DownloadModal = atom({
  key: 'h5_download_modal',
  default: false
})

export const lineKey = atom({
  key: 'line_key',
  default: 0
})

export const pcStore = atom({
  key: 'pc_platform',
  default: true
})

export const showSearchFocus = atom({
  key: 'show_pc_focus',
  default: false
})
