import styles from './index.module.scss'
import message from '@c/message'
import Image from '@c/image'
import Button from '@c/button'
import { AREA_SEARCH_GET } from '@/constant'
import AllBtn from '@c/allBtn'
import Link from 'next/link'
import { useRef } from 'react'
import cm from 'classnames'
import { useEffect, useState } from 'react'
import { useShowLogin, useWindowWidth } from '@/utils/hooks'
import { vodCollected, vodCancelCollected } from '@/api'
import EventBus from '@/utils/emitter'
import { pcStore } from '@/store'
import { useRecoilValue } from 'recoil'
import { hotSearchData, showSearchFocus } from '@/store'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'src/utils/hooks'
import { getVodCollected } from 'src/api'
import { userInfo } from 'src/store'

const Index = props => {
  const {
    showTitle = true, //是否需要展示标题行
    data,
    isHome = false, //是否是首页
    source,
    top = 0,
    isDetail = false, //是否是详情页
    hideCollect = false,
    className,
    lessItems = false
  } = props

  const [list, setList] = useState(data.cards || [])
  const topData = useRecoilValue(hotSearchData)
  const { isVip } = useRecoilValue(userInfo)
  const [height, setHeight] = useState(0)
  const [btnData, setBtnData] = useState({ url: '', name: '' })
  const ref = useRef(null)
  const [isCollected, setCollected] = useState([]) //点播是否收藏
  const [isCollet, setIsCollet] = useState(false)
  const [oncelist, setOnceList] = useState([])

  useEffect(() => {
    EventBus.on('firstLevelPage', res => {
      const currCards = res.find(item => item.name === data.name)?.cards || []
      setList(currCards)
    })
    return () => {
      EventBus.offAll('firstLevelPage')
    }
  }, [data.name])

  const setShowLogin = useShowLogin()
  const router = useRouter()

  const updateCollectStatus = (data, id, val) => {
    const updateData = data.map(item => {
      if (item.id === id) {
        item.collect = val
      }
      return item
    })
    return updateData
  }

  const initBtndata = () => {
    switch (data?.name) {
      case '电影':
        setBtnData({ url: 'movie', name: '电影' })
        break
      case '电视剧':
        setBtnData({ url: 'drama', name: '电视剧' })
        break
      case '动画片':
        setBtnData({ url: 'children', name: '动画片' })
        break
      case '动漫':
        setBtnData({ url: 'animation', name: '动漫' })
        break
      case '综艺':
        setBtnData({ url: 'variety', name: '综艺' })
        break
      case '纪录片':
        setBtnData({ url: 'documentary', name: '纪录片' })
        break
    }
    switch (source) {
      case '/movie':
        setBtnData({ url: 'movie', name: '电影' })
        break
      case '/drama':
        setBtnData({ url: 'drama', name: '电视剧' })
        break
      case '/children':
        setBtnData({ url: 'children', name: '动画片' })
        break
      case '/animation':
        setBtnData({ url: 'animation', name: '动漫' })
        break
      case '/variety':
        setBtnData({ url: 'variety', name: '综艺' })
        break
      case '/documentary':
        setBtnData({ url: 'documentary', name: '纪录片' })
        break
    }
  }

  // 更新数据&用在搜素需要更新的页面
  useEffect(() => {
    setList(data.cards || [])
  }, [data])

  const toPlay = item => {
    if (item.label === '直播') {
      let url = `/live/${item.url ? item.url : ''}`
      if (router.pathname === '/live/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    } else {
      let url = `/vod/detail/${item.id}`
      if (router.pathname === '/vod/detail/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    }
  }
  const collected = async cid => {
    const token = localStorage.getItem('token')
    if (!token) {
      setShowLogin(true)
      return
    }
    let res = null
    if (isCollected.includes(cid)) {
      res = await vodCancelCollected(cid, token)
    } else {
      res = await vodCollected(cid, token)
    }
    if (!res.code) {
      message({ content: isCollected.includes(cid) ? '取消收藏成功' : '收藏成功！' })

      if (isCollected.includes(cid)) {
        setCollected(isCollected => isCollected.filter(item => item !== cid))
      } else {
        setCollected([...isCollected, cid])
      }
    } else {
      message({ content: res.msg })
    }
  }
  // const collected = async (type, id) => {
  //   const token = localStorage.getItem('token')
  //   if (!token) {
  //     setShowLogin(true)
  //     return
  //   }
  //   let res = null
  //   if (type) {
  //     res = await vodCancelCollected(id, token)
  //   } else {
  //     res = await vodCollected(id, token)
  //   }
  //   if (!res.code) {
  //     message({ content: type ? '取消收藏成功' : '收藏成功！' })
  //     setList(updateCollectStatus(list, id, type ? 2 : 1))
  //     //做处理
  //   } else {
  //     message({ content: res.msg })
  //   }
  // }

  // 收藏
  const toggleCollect = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    collected(data.collect === 1, data.id)
  }

  useEffect(() => {
    initBtndata()
  }, [])

  //动态监听宽度

  useEffect(() => {
    const handleResize = () => {
      let h = 0
      if (window.innerWidth >= 1824) {
        h = (1824 / 6) * 0.6 - 24
      } else if (1280 <= window.innerWidth && window.innerWidth < 1824) {
        h = (window.innerWidth / 6) * 0.6 - 34
      } else if (1024 <= window.innerWidth && window.innerWidth < 1280) {
        h = (window.innerWidth / 4) * 0.6 - 44
      } else if (768 <= window.innerWidth && window.innerWidth < 1024) {
        h = (window.innerWidth / 4) * 0.6
      } else {
        h = (window.innerWidth / 4) * 0.6
      }
      setHeight(h)
    }

    // 组件挂载后监听窗口大小变化
    window.addEventListener('resize', handleResize)
    handleResize()
    // 组件卸载时移除监听器
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getCollected = async id => {
    setOnceList([...oncelist, id])
    if (oncelist.includes(id)) return
    const res = await getVodCollected(id)
    if (res && res.code === 0 && res.msg === 'ok') {
      const isFavorite = res.data.isFavorite
      setIsCollet(isFavorite)
      if (isFavorite) {
        setCollected([...isCollected, id])
      }
    }
  }

  return (
    <>
      <div></div>
      {data.cards.length > 0 && (
        <div className={cm(styles.container)}>
          <div className={styles.leftContainer}>
            {showTitle && (
              <div className={styles.moduleTop}>
                <div
                  onClick={() => router.push(`/${btnData.url}/all/all/all`)}
                  className={cm(styles.moduleBtn, 'hideWhenPhone')}>
                  <div className={styles.moduleName}>{data?.name}</div>
                  <img loading="lazy" src="/api/images/back" alt="切换" />
                  <div className={styles.show}>
                    <p>查看全部{btnData.name}</p>
                    <img loading="lazy" src="/api/images/back" alt="切换" />
                  </div>
                </div>

                <>
                  <div
                    onClick={() => router.push(`/${btnData.url}/all/all/all`)}
                    className={cm(styles.moduleBtn, 'hideWhenPc')}>
                    <div className={styles.moduleName}>热播{data?.name}</div>
                    {/* <p>查看全部{btnData.name}</p> */}
                    <div className={styles.more}>
                      更多
                      <img loading="lazy" src="/api/images/back" alt="切换" style={{ width: '12px', height: '12px' }} />
                    </div>
                  </div>
                </>
              </div>
            )}
            <div className={styles.contents}>
              <div className={styles.content}>
                {list.map((item, index) => {
                  return (
                    <div
                      className={cm(styles.cardList)}
                      key={index}
                      onMouseEnter={() => getCollected(item.id)}
                      onMouseLeave={() => setIsCollet(false)}>
                      <Link href={`/vod/detail/${item.id}`} style={{ height: height * 2.5 }}>
                        {/* <Image  src={item.img} className={styles.cover}  /> */}
                        <div className={cm(styles.imgs)} style={{ height: height * 2.5 }}>
                          <Image
                            className={styles.img}
                            src={item.img || item.poster}
                            alt={item.name || '详情海报'}
                            style={{ height: height * 2.5 }}
                          />
                          <div className={cm(styles.infolayerall)}></div>
                        </div>
                        <div className={cm('textEllipsis', styles.name)}>{item.name}</div>
                        <div className={cm(styles.largeCard, 'hideWhenPhone')}>
                          <Image
                            className={styles.lgimg}
                            src={item.poster || item.img}
                            alt={item.name || '详情海报'}
                            id="img"
                          />
                          <div className={styles.lgbtn}>
                            <Button
                              className={styles.mbtn}
                              onClick={event => {
                                router.push(isVip ? `/vod/play-official/${item.id}/1` : `/vod/play-thrid/${item.id}/1`)
                                event.preventDefault()
                              }}>
                              <img loading="lazy" src="/api/images/h-play" alt="播放" />
                              {/* <span>播放</span> */}
                            </Button>
                            <Button
                              hover="scale"
                              className={styles.btnCollect}
                              onClick={event => {
                                collected(item.id)
                                event.preventDefault()
                              }}>
                              {isCollected.includes(item.id) || isCollet ? (
                                <>
                                  <img loading="lazy" src="/api/images/h-collected" alt="取消收藏" />
                                  {/* <span>取消收藏</span> */}
                                </>
                              ) : (
                                <>
                                  <img loaßding="lazy" src="/api/images/h-collect" alt="添加收藏" />
                                  {/* <span>添加收藏</span> */}
                                </>
                              )}
                            </Button>
                          </div>
                          {item.state == 2 ? (
                            <img src="/api/images/gfxl.png" alt="官方线路" className={styles.official} />
                          ) : (
                            ''
                          )}
                          <div className={styles.lgbar}>
                            <h1>{item.name}</h1>
                            <div className={styles.scores}>
                              {item.tmdbScore > 0 || item.doubanScore > 0 ? (
                                <>
                                  <img src="/api/images/星星.png" alt="星星" loading="lazy" style={{ width: '12px' }} />
                                  <div className={styles.lgscore}>
                                    {item.tmdbScore > 0 ? item.tmdbScore : item.doubanScore}
                                  </div>
                                  <div className={styles.line}></div>
                                </>
                              ) : null}
                              <div>TV-PG</div>
                              <div className={styles.line}></div>
                              <div>{item.time?.slice(0, 4) || 2024}</div>
                            </div>

                            <div className={styles.labels}>
                              {item.label && item.label !== ''
                                ? JSON.parse(item.label).map((label, index) => {
                                    if (index > 2) return null
                                    else
                                      return (
                                        <div className={styles.label} key={index}>
                                          {label}
                                        </div>
                                      )
                                  })
                                : null}
                            </div>
                            {/* <div className="flex">
                              <p>添加： </p>
                              {item.time}
                            </div> */}

                            <div className={styles.descs} style={{ height: parseInt((height * 1.1) / 20) * 20 }}>
                              {item.desc !== '' && item.desc ? item.desc : '暂无描述'}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Index
