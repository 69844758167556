import styles from './index.module.scss'
import message from '@c/message'
import Image from '@c/image'
import { AREA_SEARCH_GET } from '@/constant'
import Link from 'next/link'
import Button from '@c/button'
import cm from 'classnames'
import { useEffect, useState, useRef } from 'react'
import { useShowLogin } from '@/utils/hooks'
import { vodCollected, vodCancelCollected } from '@/api'
import EventBus from '@/utils/emitter'
import { pcStore } from '@/store'
import { useRecoilValue } from 'recoil'
import { hotSearchData, showSearchFocus } from '@/store'
import { useRouter } from 'next/router'
import { userInfo } from 'src/store'
const updateCollectStatus = (data, id, val) => {
  const updateData = data.map(item => {
    if (item.id === id) {
      item.collect = val
    }
    return item
  })
  return updateData
}

const Index = props => {
  const {
    showTitle = true, //是否需要展示标题行
    data,
    isHome = false, //是否是首页
    source,
    top = 0,
    isDetail = false, //是否是详情页
    hideCollect = false,
    className,
    lessItems = false
  } = props
  const [list, setList] = useState(data.cards || [])
  const topData = useRecoilValue(hotSearchData)
  const { isVip } = useRecoilValue(userInfo)
  const [isCgImgUrl, setIsCgImgUrl] = useState(false)
  const router = useRouter()
  const ref = useRef(null)
  const contentref = useRef(null)
  const [num, setNum] = useState(0)
  const [isCollected, setCollected] = useState([]) //点播是否收藏
  const setShowLogin = useShowLogin()
  const toPlay = item => {
    if (item.label === '直播') {
      let url = `/live/${item.url ? item.url : ''}`
      if (router.pathname === '/live/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    } else {
      let url = `/vod/detail/${item.id}`
      if (router.pathname === '/vod/detail/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    }
  }

  const sliceList = index => {
    setList((data.cards || []).slice(index, index + 6))
  }

  const changeRef = index => {
    if (index === 0) {
      contentref.current.style.justifyContent = 'left'
      return
    }
    if (index === 5) {
      contentref.current.style.justifyContent = 'right'
      return
    }
    contentref.current.style.justifyContent = 'center'
  }

  const changePage = () => {
    if (num < data.cards.length - 6) {
      const index = num + 6
      setNum(num => num + 6)
      sliceList(index)
    }
    if (num >= 6) {
      const index = num - 6
      setNum(num => num - 6)
      sliceList(index)
    }
  }

  useEffect(() => {
    sliceList(0)
  }, [])

  const collected = async cid => {
    console.log(cid)
    const token = localStorage.getItem('token')
    if (!token) {
      setShowLogin(true)
      return
    }
    let res = null
    if (isCollected.includes(cid)) {
      res = await vodCancelCollected(cid, token)
    } else {
      res = await vodCollected(cid, token)
    }
    if (!res.code) {
      message({ content: isCollected.includes(cid) ? '取消收藏成功' : '收藏成功！' })

      if (isCollected.includes(cid)) {
        setCollected(isCollected => isCollected.filter(item => item !== cid))
      } else {
        setCollected([...isCollected, cid])
      }
    } else {
      message({ content: res.msg })
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        ref.current.style.height = window.innerWidth / 7
      }
    }

    // 组件挂载后监听窗口大小变化
    window.addEventListener('resize', handleResize)
    handleResize()
    // 组件卸载时移除监听器
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <>
      <div></div>
      <div className={cm(styles.container, 'hideWhenPhone')}>
        <div className={styles.leftContainer}>
          <div className={styles.moduleName}>热映推荐</div>
          {num < 6 || list.length === 0 ? null : (
            <div className={styles.leftbtn} onClick={changePage}>
              ＜
            </div>
          )}
          {num < data.cards?.length - 6 && list.length !== 0 ? (
            <div className={styles.rightbtn} onClick={changePage}>
              ＞
            </div>
          ) : null}
          <div className={styles.content2} ref={contentref}>
            {list.map((item, index) => {
              return (
                <div
                  className={styles.contenta}
                  key={index}
                  onMouseEnter={() => changeRef(index)}
                  onMouseLeave={() => {
                    changeRef(-1)
                  }}
                  style={
                    index === 0
                      ? { marginLeft: '0', justifyContent: 'left' }
                      : index < 5
                      ? { marginRight: '8px', marginLeft: '8px', justifyContent: 'center' }
                      : { marginRight: '0', justifyContent: 'right' }
                  }>
                  <div>
                    {/* style={index < 5 ? { marginRight: '20px' } : null} */}
                    <div className={cm(styles.cards)}>
                      <div className={cm(styles.cardList)}>
                        <Link href={`/vod/detail/${item.id}`}>
                          <div className={styles.imgs}>
                            <Image className={styles.img1} src={item.img} alt={item.name || '详情海报'} />
                            <Image className={styles.img2} src={item.poster} alt={item.name || '详情海报'} />
                          </div>
                        </Link>
                        {/* <img src={item.img} className={styles.cover} /> */}
                        {item.state == 2 ? (
                          <img src="/api/images/gfxl.png" className={styles.official} alt="官方线路" />
                        ) : (
                          ''
                        )}
                        <div className={styles.lgbar}>
                          <div className="flex items-end">
                            {item.label && item.label !== ''
                              ? JSON.parse(item.label).map((label, index) => {
                                  if (index > 2) return null
                                  else
                                    return (
                                      <div className={styles.label} key={index}>
                                        {label}
                                      </div>
                                    )
                                })
                              : null}
                          </div>
                          <div className="flex items-center ">
                            <Button hover="scale" className={styles.btnCollect} onClick={() => collected(item.id)}>
                              {isCollected.includes(item.id) ? (
                                <>
                                  <img loading="lazy" src="/api/images/h-collected" alt="取消收藏" />
                                  {/* <span>取消收藏</span> */}
                                </>
                              ) : (
                                <>
                                  <img loaßding="lazy" src="/api/images/h-collect" alt="添加收藏" />
                                  {/* <span>添加收藏</span> */}
                                </>
                              )}
                            </Button>
                            <Button
                              link
                              href={isVip ? `/vod/play-official/${item.id}/1` : `/vod/play-thrid/${item.id}/1`}
                              className={styles.mbtn}>
                              <img loading="lazy" src="/api/images/h-play" alt="播放" />
                              {/* <span>播放</span> */}
                            </Button>
                          </div>
                        </div>
                        <div className={styles.TopNumContainer}>
                          {index <= 2 ? (
                            <>
                              <img src={`/api/images/top_num_${index + 1}.png`} className={styles.top} alt="top" />
                            </>
                          ) : (
                            <>
                              <img src="/api/images/top_num_index.png" className={styles.top} alt="top" />
                            </>
                          )}

                          {/* <img  src='/api/images/top_num_1.png'  className={styles.top}  /> */}
                          <span className={styles.topNum}>{index + num + 1}</span>
                        </div>
                        {item.doubanScore > 0 || item.tmdbScore > 0 ? (
                          <div className={styles.score}> {item.tmdbScore > 0 ? item.tmdbScore : item.doubanScore}</div>
                        ) : null}

                        {/* {item.tmdbScore}
                      {item.doubanScore}
                      {item.time}
                      {item.label} */}
                        <div className={cm('textEllipsis', styles.name)}>{item.name}</div>
                        <div className={cm('textEllipsis', styles.desc)}>{item.desc}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* <div className={styles.rightContainer}>
          <div className={styles.moduleName}>
            搜索剧集榜单
            <img src="/api/images/search.png" className={styles.icon} />
          </div>

          <div className={styles.content}>
            {topData[0]?.map((item, index) => {
              return (
                <div className={styles.item}>
                  <p
                    className={cm(styles.hotIndex, {
                      [styles.one]: item.index === 1,
                      [styles.two]: item.index === 2,
                      [styles.three]: item.index === 3
                    })}>
                    {item.index}
                  </p>
                  <div className={styles.name} onClick={() => toPlay(item)}>
                    <div className={cm('textEllipsis')}>{item.name}</div>

                    <span className={styles.label}>{item.label}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Index
