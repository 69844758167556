import dynamic from 'next/dynamic'
import omit from 'lodash-es/omit'
import { useRouter } from 'next/router'
import { devicePlatform } from '@/utils/utils'
import { useEffect, useState } from 'react'
import { userInfo, hotSearchData } from '@/store'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { getUserInfo, getTopData } from '@/api'
import chunk from 'lodash-es/chunk'
import { h5DownloadModal, pcStore } from '@/store'
import dayjs from 'dayjs'
import EventBus from '@/utils/emitter'
import Header from '@c/header'
import HeaderPhone from '@c/header-phone'
import { useSetUserInfo } from '@/utils/hooks'
import { showSide } from 'src/store'
import Cookies from 'js-cookie';
const Backup = dynamic(() => import('@c/backup'))
const ModalDownload = dynamic(() => import('@c/modal/modal-download'))
const LoginBox = dynamic(() => import('@c/login-box'))
const ModalLogout = dynamic(() => import('@c/modal/modal-logout'))
const ModalPay = dynamic(() => import('@c/modal/modal-pay'))
const ModalPayH5 = dynamic(() => import('@c/modal/modal-pay-h5'))
const ModalRobot = dynamic(() => import('@c/modal/modal-robot'))
const InviteBox = dynamic(() => import('@c/modal/modal-invite'))
const ShowInfo = dynamic(() => import('@c/modal/modal-info'))
const Index = ({ Component, pageProps }) => {
  const router = useRouter()
  const [user, setUserInfo] = useSetUserInfo()
  const setHotSearchData = useSetRecoilState(hotSearchData)
  const { pathname } = router
  const [showModal, setModal] = useRecoilState(h5DownloadModal)
  const [openVip, setOpenVip] = useState(false)
  const [openOut, setOpenOut] = useState(false)
  const [openIn, setOpenIn] = useState(false)
  const [currKey, setCurrKey] = useState(1)
  const [pc, setPc] = useRecoilState(pcStore)
  const [showInvite, setShowInvite] = useState(false)
  const [showRobot, toggleShowRobot] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const show = useRecoilValue(showSide)
  // 移动设备弹窗,且在某些路由下不弹窗,增加逻辑，一天只展示一次
  const popModal = isPC => {
    const now = dayjs().format('YYYY-MM-DD')
    const { date, isOpen = false } = JSON.parse(localStorage.getItem('pop_time') || '{}')
    if (['/privacy', '/download', '/vip', '/invite'].includes(pathname)) {
      return
    }
    if (now === date && isOpen) {
      return
    }
    if (!isPC) {
      localStorage.setItem(
        'pop_time',
        JSON.stringify({
          date: now,
          isOpen: true
        })
      )

      setTimeout(() => {
        setModal(true)
      }, 500)
    }
  }

  // 热搜索数据获取
  const getHotData = async () => {
    try {
      const res = await getTopData()
      const initData = res.data.data.map((item, index) => ({
        ...item,
        index: index + 1
      }))
      setHotSearchData(chunk(initData, 6))
    } catch (error) {}
  }

  // 存储邀请人id

  useEffect(() => {
    const { invite_id, token, c, appChannel } = router.query
    if (invite_id) {
      localStorage.setItem('invite_id', invite_id)
    }

    if (c || appChannel) {
      const channel = c || appChannel
      localStorage.setItem('app_channel', channel)
    }

    if (token && token.split('.').length === 3) {
      const logintoken = localStorage.getItem('token')
      if (!logintoken) {
        localStorage.setItem('token', token)
		Cookies.set('token', token);
      }
    }

    const { isPC } = devicePlatform()
    setPc(isPC)
    const tok = localStorage.getItem('token')

    setUserInfo(tok)
    getHotData()
    popModal(isPC)
  }, [])

  // 用户属性设置。
  useEffect(() => {
    setTimeout(() => {
      try {
        gtag('set', 'user_properties', {
          user_logged_in: user?.isLogin ? 'true' : 'false',
          user_is_vip: user?.isVip ? 'true' : 'false',
          enter_page: router.asPath
        })
      } catch (error) {}
    }, 200)
  }, [router.asPath, user])

  const withoutHeaderArr = [
    // '/vod/play-thrid/[id]/[ep]',
    // '/vod/play-official/[id]/[ep]',
    '/privacy',
    // '/live/[id]',
    '/zj/[id]/[episode]',
    '/userinfo',
    '/search-phone',
    '/invite',
    '/invite-h5'
    //    '/live/[id]',
    // '/sports/live/[id]'
  ]

  const withoutPhoneHeader = [
    '/vod/play-thrid/[id]/[ep]',
    '/vod/play-official/[id]/[ep]',
    '/vod/detail/[id]',
    '/live/[id]',
    '/sports/live/[id]'
  ]

  useEffect(() => {
    EventBus.on('openVipDialog', key => {
      setCurrKey(key)
      setOpenVip(true)
    })
    EventBus.on('openLoginOut', () => {
      setOpenOut(true)
    })
    EventBus.on('openIn', () => {
      setOpenIn(true)
    })
    EventBus.on('toggleRobot', type => {
      toggleShowRobot(type)
    })
    EventBus.on('invite', () => {
      setShowInvite(true)
    })
    EventBus.on('showInfo', () => {
      setShowInfo(true)
    })
    return () => {
      EventBus.offAll('openVipDialog')
      EventBus.offAll('openLoginOut')
      EventBus.offAll('openIn')
      EventBus.offAll('toggleRobot')
      EventBus.offAll('showInfo')
    }
  }, [])

  return (
    <>
      <main className="layoutMain">
        {withoutHeaderArr.includes(pathname) || !show ? null : (
          <>
            <Header className="hideWhenPhone" url={pageProps.layoutProps.url} vipData={pageProps.vipData} />
            {withoutPhoneHeader.includes(pathname) ? null : <HeaderPhone className="hideWhenPc" />}
            <Backup />
          </>
        )}

        <Component {...omit(pageProps, ['layoutProps'])} />
      </main>
      <div id="message-root"></div>
      {showModal ? <ModalDownload onClose={() => setModal(false)} /> : null}
      {/* 登入 */}
      {openIn && <LoginBox onClose={() => setOpenIn(false)} />}
      {/* 登出 */}
      {openOut && <ModalLogout onClose={() => setOpenOut(false)} />}
      {showInvite && <InviteBox onClose={() => setShowInvite(false)} />}
      {/* 套餐弹窗 */}
      {showInfo && <ShowInfo onClose={() => setShowInfo(false)} />}
      {openVip ? (
        pc ? (
          <ModalPay vipData={pageProps.vipData} currKey={currKey} onClose={() => setOpenVip(false)} />
        ) : (
          <ModalPayH5 vipData={pageProps.vipData} currKey={currKey} onClose={() => setOpenVip(false)} />
        )
      ) : null}
      {showRobot && <ModalRobot />}
    </>
  )
}

export default Index
