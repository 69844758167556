import styles from './index.module.scss'
import cm from 'classnames'
import Button from '../button'
import Link from 'next/link'
import { useRecoilValue } from 'recoil'
import { userInfo } from 'src/store'

const Desc = props => {
  const { title, desc, id, eporder } = props.data || {}
  const { isVip } = useRecoilValue(userInfo)

  return (
    <div className={styles.desc}>
      <Link href={`/vod/detail/${id}`} className={cm(styles.firstSeries, 'textEllipsis')}>
        {title}
      </Link>
      <p className={cm(styles.secondSeries, 'hideWhenPhone')}>{desc}</p>
      <div className={styles.btnGroup}>
        <Button className={styles.btn} link href={isVip ? `/vod/play-official/${id}/1` : `/vod/play-thrid/${id}/1`}>
          <img loading="lazy" src="/api/images/h-play" alt="播放跳转" />
          <span>
            播放
            {/* {id}/ {eporder} */}
          </span>
        </Button>
        <Button className={cm(styles.btn, styles.btnDetail)} link href={`/vod/detail/${id}`}>
          <img loading="lazy" src="/api/images/h-detail" alt="详情页跳转" className={styles.detalimg} />
          <span>详情</span>
        </Button>
      </div>
    </div>
  )
}

export default Desc
